.ButtonsContainer {
  font-size: 1rem;
}
.HeaderText {
  font-size: 1em;
}
.link {
  text-decoration: none;
  color: var(--primary);
}
@media screen and (max-width: 768px) {
  .HeaderText {
    font-size: 12px;
  }
}
