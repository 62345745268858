.ButtonsContainer {
  padding: 10px 0px;
  border-radius: 5px;
  cursor: pointer;
  height: auto;
  width: auto;
}
.checkIcon img {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .ButtonsContainer {
    flex-direction: row;
    align-items: center;
  }
  .checkIcon {
    margin-right: 4px;
    width: 16px;
  }
}
