.WhyContainer {
  background-color: #f2f2f4;
  height: 100vh;
}
.mainContainer {
  display: flex;
  align-items: center;
  justify-content: "space-between";
  width: 100%;
  overflow: hidden;
}
.subcontainer {
  width: 80%;
}
.banner {
  position: relative;
}
.pos {
  position: absolute;
}
