.row {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.col {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    justify-content: flex-start;
  }
}
