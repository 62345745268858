.WhyContainer {
  background-color: #fff;
  height: 500px;
  justify-content: center;
  align-items: center;
}
.subcontainer {
  width: 80%;
}
.cards {
  width: 100%;
  margin-top: 50px;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .WhyContainer {
    height: auto;
    margin-top: 50px;
    align-items: center;
  }
  .cards {
    display: grid;
    width: 100%;
    margin-top: 50px;
    grid-template-columns: 1fr 1fr;
  }
}
