.container {
  height: 100vh;
  background-image: url("../../assets/images/BG.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}
.formContainer {
  width: 400px;
  background-color: #fff;
  padding: 20px 40px;
  padding-bottom: 60px;
  border-top: 5px solid #FF6700;
  margin-top: 300px;
}
.inputsHolder {
  width: 100%;
  margin-top: 20px;
}
.width {
  width: 100%;
}
.checkbox {
  margin-top: 10px;
}
.selection {
  width: 100%;
  margin-top: 10px;
}
.selected {
  width: 100%;
  cursor: pointer;
  padding: 10px 0px;
}
.selected:hover {
  background-color: #ebebeb;
}
.active {
  background-color: #ebebeb;
}
