.ButtonsContainer {
  flex-direction: row;
  background-color: #f7f6fc;
  padding: 13px 20px;
  border-radius: 5px;
  cursor: pointer;
  height: auto;
  width: auto;
  margin-bottom: 20px;
}
.startedListText {
  font-size: 1em;
}
@media screen and (max-width: 768px) {
  .ButtonsContainer {
    margin-bottom: 0px;
    padding-bottom: 8px;
  }
  .imgContainer {
    width: 24px;
  }
  .startedListText {
    font-size: 12px;
  }
}
