.overfold {
  display: flex;
  box-sizing: border-box;
  /*padding: 1.5rem 3.5rem;*/

  position: relative;

  background: none;
  color: white;

  text-transform: uppercase;
  /*border: 1px solid currentColor;*/

  --progress: 100%;
}

.overfold:before {
  content: "";
  z-index: -1;

  position: absolute;
  inset: 0;

  background: white;
  clip-path: polygon(
    100% 0,
    var(--progress) var(--progress),
    0 100%,
    100% 100%
  );
  transition: clip-path 0.2s;
}
.overfold:hover {
  --progress: 0%;
}

.overfold .text-container {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 8px;
}
.overfold .text {
  display: block;
  position: relative;
  font-size: 1rem;
  font-weight: 900;
  mix-blend-mode: difference;
}
.overfold:hover .text {
  animation: move-up-alternate 0.3s forwards;
  will-change: transform;
}

@keyframes move-up-alternate {
  from {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  100% {
    transform: translateY(0%);
  }
}

.flicker {
  border: 1px solid;
  overflow: hidden;
  position: relative;
}

.flicker,
.flicker-text {
  z-index: 20;
}

.flicker:after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}

.flicker:hover,
.flicker:after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 768px) {
  .overfold {
    margin-block: 8px;
    align-items: center;
    justify-content: space-evenly;
  }
}
