.link{
    font-family: 'Sarabun', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 30px */


    color: rgba(42, 90, 255, 0.89);
}
.link:hover {
    color: rgba(255, 81, 10, 0.97);
}
.icon-holder {
    background: #FFEDE6;
    border-radius: 50px;


}
