.WhyContainer {
  background-color: #f2f2f4;
  height: 75vh;
  border-top: 1px solid #9191a1;
  position: relative;
}
.mainContainer {
  background-color: #ffffff;
  width: 80%;
  padding: 0px 50px;
  border-radius: 10px;
  margin: auto;
  height: 100%;
}
.containerDimensions {
  margin-top: 1em;
}
.subContainer {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .mainContainer {
    width: 100%;
    padding: 0;
  }
  .subContainer {
    display: flex;
    justify-content: space-between;
  }
  .containerDimensions {
    width: 60%;
    height: 100%;
  }
  .WhyContainer {
    background-color: #f2f2f4;
    height: 100%;
    border: none;
  }
  .pos {
    height: 139px;
    overflow: hidden;
    width: 183px;
  }

  .banner {
    width: 100%;
    left: 100px;
    top: 100px;
    position: absolute;
  }
}
