.NavContainer {
  background-color: #fff;
  height: auto;
}
.footer{
  display: block;
  width:auto
}
.subcontainer {
  width: auto;
  height: 60%;
  justify-content: space-evenly;
  padding: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}

.links {
  display: block;
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  padding-right: 8px;
}

.linkContainer {
  display: inline-flex;
  width: fit-content;
}
@media screen and (max-width: 768px) {
  .NavContainer {
    height: auto;
  }
  .subcontainer {
    width: auto;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
