.CustomerSupportContainer {
  background-color: #000000;
  justify-content: center;
}
.subContainer {
  width: 80%;
  justify-content: center;
  margin: 20px;
}
.eachFlex {
  width: 80%;
}
.iconset {
  width: 100%;
  margin-top: 50px;
}
.another {
  margin-left: 50px;
}
.input {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 7px;
  margin-top: 25px;
}
.input input {
  flex: 1;
  background: none;
  border: none;
  padding-left: 20px;
  outline: 0;
  color: #fff;
}
.inputEMail::placeholder {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .subContainer {
    width: 100%;
    align-items: center;
  }
  .urls {
    width: fit-content;
  }
}
