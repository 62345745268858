@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary:#FF4B01;
  --secondary:#FFF4F0;
--red:#FF4B01;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active:#DDD7D7;
  --tick: #fff;
  position: relative;
  display: flex;
  align-items: center;
}
.checkbox input,
.checkbox svg {
  width: 21px;
  height: 21px;
  display: block;
}
.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: #e4e4e4;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, #FF4B01);
  margin-right: 5px;

}
.checkbox input:hover {
  --s: 2px;
  --b: var(--border-hover);
}
.checkbox input:checked {
  --b: var(--red);
}
.checkbox svg {
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, var(--red));
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  transform: scale(var(--scale, 1)) translateZ(0);
}
.checkbox.path input:checked {
  --s: 2px;
  transition-delay: 0.4s;
}
.checkbox.path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}
.checkbox.path svg {
  /* stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12); */
   stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}
.checkbox.bounce {
  --stroke: var(--tick);
}
.checkbox.bounce input:checked {
  --s: 11px;
}
.checkbox.bounce input:checked + svg {
  -webkit-animation: bounce 0.4s linear forwards 0.2s;
  animation: bounce 0.4s linear forwards 0.2s;
}
.checkbox.bounce svg {
  --scale: 0;
}

@-webkit-keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

* {
  box-sizing: inherit;
}
*:before,
*:after {
  box-sizing: inherit;
}


.grid {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
  margin-top: 10px;
  
}
.grid2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
    margin-top: 5px;
  }
.checkit{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}