.ButtonsContainer {
  background-color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ButtonsContainer:hover {
}
.ButtonsPlain{
  padding: 10px 25px;
  cursor: pointer;
  height: auto;
  width: auto;
  width: calc(100% - 50px);
}
