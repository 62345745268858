.text {
  font-family: DM Sans;
  font-style: Regular;
  font-size: 16px;
  line-height: 20px;
  line-height: 100%;
  /* Align: Left */
  /* Vertical align: Top */
  text-align: left;
  /* color: rgba(0, 0, 0, 0.6); */
  font-weight: 800;
  color: #000000;
}
.currency {
  border-bottom: 3px solid var(--red);
  margin-right: 2px;
}
.input {
  background: none;
  border: none;
  width: 100%;
  outline: 0;
}
.inputnoBorder{
border-top: none !important;
border-left: none !important;
border-right: none !important;
/* border-bottom: none !important; */
border-radius: 0px !important;
padding-left: 0px !important;
}
input::placeholder {
  color: rgba(0, 0, 0, 0.43);
  font-family: DM Sans;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  line-height: 100%;
}
.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 5px 0px;
}
.input_container {
  width: calc(100% - 40px);
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 15px;
  border-radius: 4px;
  margin: 10px 0px;
  position: relative;
}
/* .select{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.code {
  position: absolute;
  top: 40%;
  right: 0;
  font-size: 9px;
  margin-right: 80px;
}
.code code {
  background-color: rgba(221, 215, 215, 0.32);
}
.img {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
  height: 20px;
  margin-top: 5px;
}
.select {
  width: 100%;
  display: grid;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  grid-template-areas: "select";
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 101%;
  background-position-y: 0px;
  padding-bottom: 5px;
  padding-top: 4px;
  padding-left: 5px;
  height: 10%;
}

select,
.select:after {
  grid-area: select;
}

.select::-ms-expand {
  display: none;
}

.select > option {
  border: 1px solid red;
}
.single{
  width: 35px;
  border: none;
  background: none;
  border-bottom: 2px solid #000000;
  height: 30px;
  outline: 0;
  font-size: 24px;
}