.NavContainer {
  background-color: #000000;
  height: 55vh;
}
.subContainer {
  width: 80%;
}
.eachFlex {
  width: 80%;
}
.iconset {
  width: 100%;
  margin-top: 25px;
}
.another {
  margin-left: 50px;
}
.input {
  width: 100%;
  padding: 15px;
  /*padding: 15px 0 15px 5px;*/
  background: #ebebeb;
  opacity: 0.35;
  border: 0.5px solid #a6a6a6;
  /*border-left: none;*/
  border-radius: 2px;
  /*border-bottom-right-radius: 2px;*/
  /*border-top-right-radius: 2px;*/
  min-width: 310px;
  margin-top: 15px;
  outline: none;
}
.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  opacity: 1; /* Firefox */
}
.label {
  width: 100%;

  margin-top: 15px;
  background: #f8f8f8;
  padding: 12px 0 13px 20px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border: 0.5px solid rgba(166, 166, 166, 0.33);
  border-right: none;
}

.textArea {
  width: 100%;
  padding: 15px 20px;
  background: #ffffff;
  opacity: 0.66;
  /* Primary Color */
  height: 75px;

  border: 0.5px solid #FF6700;
  box-shadow: 0 1px 10px rgba(255, 68, 0, 0.5);
  border-radius: 2px;
  max-width: 300px;
  margin-top: 15px;
}
.input input {
  flex: 1;
  background: none;
  border: none;
  padding-left: 20px;
  outline: 0;
  color: #fff;
}
.inputEMail::placeholder {
  color: #fff;
}
.colWidth {
  max-width: 500px;
}
.address {
  font-family: "Sarabun", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* or 182% */
  padding-bottom: 10px;
  /* Gray 3 */

  color: #828282;
}
.headers {
  font-family: "Open Sans", "Droid Serif", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 152% */

  /* Gray 2 */

  color: #4f4f4f;
}
.tagLine {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 202% */
  padding-bottom: 15px;

  color: #333333;
}
