.WhyContainer {
  background-color: #fff;
  margin: 20px;
}
.subcontainer {
  width: 100%;
}
.secondSub {
  width: 80%;
  background-color: var(--secondary);
  padding: 20px 0px;
  margin: 50px 0px;
}
.cardHolder {
  width: 80%;
}
@media screen and (max-width: 768px) {
  .secondSub {
    width: auto;
    padding: 16px;
    margin: 0px;
  }
  .cardHolder {
    width: auto;
  }
  .imgContainer {
    width: 144px;
    height: 144px;
    margin: auto;
  }
}
