.slideUp {
  animation: slideUp 1s ease-in-out 0.5s forwards;
  transform: translateY(50px);
  opacity: 0;
}
.slideUp_no {
    animation: slideUp 1s ease-in-out forwards;
    transform: translateY(50px);
    opacity: 0;
  }
.slideUp_2_0 {
  animation: slideUp 1s ease-in-out 0.95s forwards;
  transform: translateY(50px);
  opacity: 0;
}
.opacity{
    opacity: 0;
    transition:all ease-in-out .3s;
}
.scale {
  animation: scale 1s ease-in-out 1.2s forwards;
  transform: scale(0.2);
  opacity: 0;
}
.scale_2_0 {
    animation: scale 1s ease-in-out 1.5s forwards;
    transform: scale(0.2);
    opacity: 0;
  }
  .scale_3_0 {
    animation: scale 1s ease-in-out 2s forwards;
    transform: scale(0.2);
    opacity: 0;
  }
.slideLeft{
    animation: slideLeft 1s ease-in-out 2s forwards;
    transform: translateX(100%);
    opacity: 0;
}
.slideRight{
    animation: slideRight 1s ease-in-out 2s forwards;
    transform: translateX(100%);
    opacity: 0;
}



/* for the benefits */
.slideUp_no_0 {
    animation: slideLeft 1s ease-in-out forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_1 {
    animation: slideLeft 1s ease-in-out .3s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_2 {
    animation: slideLeft 1s ease-in-out .45s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_3 {
    animation: slideLeft 1s ease-in-out .6s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_4 {
    animation: slideLeft 1s ease-in-out .75s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_5 {
    animation: slideLeft 1s ease-in-out .9s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_6 {
    animation: slideLeft 1s ease-in-out 1.1s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_7 {
    animation: slideLeft 1s ease-in-out 1.2s forwards;
    transform: translateX(50px);
    opacity: 0;
  }
  .slideUp_no_8 {
    animation: slideLeft 1s ease-in-out 1.3s forwards;
    transform: translateX(50px);
    opacity: 0;
  }



  .rot {
    animation: rotateSmall 1s ease-in-out forwards;
    transform: rotate(20deg);
    opacity: 0;
  }
@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slideLeft {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }
@keyframes slideRight {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }
@keyframes scale {
  from {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotateSmall {
    from {
      transform: rotate(20deg);
      opacity: 0;
    }
    to {
      transform: rotate(0deg);
      opacity: 1;
    }
  }
