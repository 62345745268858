.WhyContainer {
  background-color: #f2f2f4;
  height: 100vh;
}
.subcontainer {
  width: 80%;
}
.banner {
  position: relative;
}
.pos {
  position: absolute;
}
