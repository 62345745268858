.link {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 30px */

  color: rgba(42, 90, 255, 0.89);
}
.link:hover {
  color: rgba(105, 60, 42, 0.97);
}
.icon-holder-career {
  background: #2626bc;
  border-radius: 50px;
}

.benefit-of-merchant {
  margin: 2em;
}
.contents.solution img {
  height: 400px;
  width: 400px;
  object-fit: fill;
}
.col {
  width: fit-content;
}

.solution {
  width: 400px;
  height: 500px;
}
.modern-solution {
  height: 325px;
  display: block;
}
.modern-solution img {
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .modern-solution {
    height: 240px;
  }
  .solution {
    width: 325px;
    height: 430px;
  }
}
@media screen and (max-width: 768px) {
  .benefit-of-merchant {
    padding: 1rem;
    margin: 0.1em;
  }
  .modern-solution {
    height: auto;
  }
  .solution {
    width: auto;
    height: auto;
  }
  .morebenefit {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  span.content img {
    width: 100%;
    object-fit: fill;
  }
  .card {
    margin-bottom: 8px;
  }
  .content {
    width: 60px;
    display: block;
  }
}
