.WhyContainer {
  background-color: #f2f2f4;
  height: 100vh;
}
.subContainer {
  display: flex;
  align-items: center;
  width: 100%;
}
.banner {
  position: relative;
}
.pos {
  position: absolute;
}
.instantSupportStory {
  margin-left: 8px;
}
