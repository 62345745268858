.ButtonsContainer {
  background-color:var(--primary);
  padding: 10px 30px;
  height: auto;
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  cursor: pointer;

}
.ButtonsContainer:hover {
}
.overall{
  width: 100%;
  margin-bottom: 20px;

}
.text{
  padding: 30px 40px;
  width: calc(100% - 80px);
  background-color: #FFF4F0;
}
