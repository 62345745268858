.MainContainer {
  position: relative;
  width: 100%;
  max-height: calc(100vh - 70px);
  height: calc(100vh - 70px);
  justify-content: center;
  overflow: hidden;
  background: rgb(255, 244, 240);
  background: linear-gradient(
    160deg,
    rgba(255, 244, 240, 1) 39%,
    rgba(229, 229, 229, 1) 61%,
    rgba(245, 250, 218, 1) 82%
  );
}

.LeftSideImage {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: -190px;
}

.LeftSideImage img {
  width: 100%;
}
.rightSideImage {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 5px;
  width: 125px;
  margin-left: -12px;
}
.header {
  max-width: 850px;
  margin-top: -160px;
}
.buttons {
  margin-top: 25px;
}

.normal {
  max-width: 80%;
}

@media screen and (max-width: 1024px) {
  .LeftSideImage {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .MainContainer {
    justify-content: flex-start;
  }
  .buttons {
    font-size: 32px;
    flex-direction: row;
  }
  .LeftSideImage {
    opacity: 0.5;
    width: 350px;
    margin-bottom: -100px;
    width: 280px;
  }
  .rightSideImage {
    margin-bottom: 0px;
  }
  .header {
    max-width: 100%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 425px) {
  .LeftSideImage {
    width: 200px;
  }
}
