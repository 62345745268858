.CardContainer {
  background-color: #fff;
  height: 180px;
  width: 75%;
  min-width: 250px;
  padding: 15px 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.028);
  border: 0.5px solid #e0e0e0;
}
.subcontainer {
  padding-top: 10px;
  height: 100%;
}
.freg {
  background-color: #fff;
  width: 300px;
  padding: 30px 15px;
  border: 0.5px solid #e0e0e0;
  height: 180px;
}

@media screen and (max-width: 768px) {
  .CardContainer {
    width: auto;
    min-width: 100px;
    padding: 8px;
  }
  .subcontainer {
    padding: auto;
    height: auto;
  }
  .freg {
    height: auto;
  }
}
