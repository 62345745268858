.nav-container {
  background-color: var(--primary);
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
}
.brand-name {
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--primary);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .navigation-menu ul {
    display: none;
  }
}
.hamburger:hover {
  background-color: #2642af;
}
@media screen and (max-width: 768px) {
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
    z-index: 1;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
}

.subcontainer {
  width: 80%;
  height: 50px;
  padding: 10px 0px;
}
