@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root {
  --primaryFont: "Lato";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: "Lato" !important;
}
* {
  font-family: "Lato" !important;
}

.bg-orange {
  background: rgba(255, 81, 10, 0.97);
}
.text-orange {
  color: rgba(255, 81, 10, 0.97);
}
.btn-text {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 116.6%;
}
.white-button-div {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-left: 4px;
}
.pointer {
  cursor: pointer;
}
.row {
  /*margin-left: 20px;*/
  margin-right: 24px;
}
.px-50 {
  padding: 0 50px;
}
.px-75 {
  padding: 0 75px;
}
.pl-5 {
  padding-left: 5px;
}
.no-outline {
  outline: none;
}
.no-border {
  border: none !important;
}
.border-radius-4 {
  border-radius: 4px;
}
.white-button:hover {
  background: rgba(255, 81, 10, 0.97) !important;
  border: solid white;
  color: white;
}
.career-bg {
  background-image: url("./assets/svgs/careerBg.svg");
  background-size: contain;
  background-position: center;
}
.get-started-bg {
  background-image: url("./assets/svgs/getStartedBg.svg");
  background-size: contain;
  background-position: center;
}
.orange-bg {
  background-image: url("./assets/svgs/orangeBg.svg");
  background-size: contain;
  background-position: center;
}
.border-radius-21 {
  border-radius: 21px;
}
.border-shadow-bottom {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 38px;
}
.h-730 {
  max-height: 730px;
}
.solution-card {
  width: 350px;
  height: auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 29px;
  /*transform: matrix(1, 0, 0, -1, 0, 0);*/
}
.solution-title {
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.pulse {
  animation: animate 2s alternate infinite;
}

@keyframes animate {
  0% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}
.download-title {
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 102.6%;
  /* or 74px */
  max-width: 520px;

  letter-spacing: -0.03em;

  color: #000000;
}
.download-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  /* or 30px */
  max-width: 589px;

  color: #000000;
}
.download-btn {
  background: linear-gradient(180deg, #FF6700 0%, #581800 100%);
  border-radius: 10px;
}
.download-btn:hover {
  color: #581800 !important;
  background: white;
}
.download-bg {
  background: rgba(234, 233, 237, 0.6);
  /*transform: matrix(1, 0, 0, -1, 0, 0);*/
}
.pricing-bg {
  background: #f4f7fa;
  border-radius: 0 0 10px 10px;
}
.pricing-bg-white {
  background: #ffffff;
  box-shadow: 0 0 25px rgba(158, 158, 158, 0.05);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.pricing-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 116.6%;
  /* identical to box height, or 56px */

  text-align: center;

  color: #1c1c1c;
}
.pricing-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 190%;
  /* or 34px */

  text-align: center;

  color: #616161;
}
.faq-title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 116.6%;
  /* identical to box height, or 47px */

  color: #263238;
}
.faq-header {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 116.6%;
  /* identical to box height, or 56px */

  color: #ffffff;
}
.faq-question {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 116.6%;
  /* or 26px */

  color: #ffffff;
}
.faq-answer {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  /* or 35px */

  color: #4f4f4f;
}
.pos-left-62 {
  position: relative;
  left: 62px;
}
.solution-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /* or 67px */

  color: rgba(0, 0, 0, 0.8);
}

.solution-card-text {
  font-size: 1em;
}

@media screen and (max-width: 768px) {
  .App {
    width: 100% !important;
    overflow-x: hidden !important;
    font-size: 1rem;
  }
  .solution-title {
    font-family: "Lato", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
  }
  .download-title {
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 1;

    color: #000000;
  }
  .pos-left-62 {
    position: relative;
    left: 24px;
  }
}

@media screen and (max-width: 425px) {
  .pos-left-62 {
    position: relative;
    left: 150px;
  }
  .pulse {
    left: 9px;
    width: 350px;
    top: -100px;
  }

  .download-subtitle {
    font-size: 16px;
  }
}
/* 

  color: rgba(0, 0, 0, 0.8);
} */

/* @media screen and (max-width: 40em) {
  .App {
    width: 100% !important;
    overflow-x: hidden !important;
    font-size: 1rem;
  }
  .styles_col__kevlB.styles_banner__WlNW- {
    display: none !important;
  }
  .styles_pos__9FBIH img {
    display: none !important;
  }
  .styles_mainContainer__Dbp0N {
    background-color: #ffffff;
    width: 100% !important;
    padding: 0px 50px !important;
    border-radius: 10px;
  }
  .styles_col__kevlB.styles_text__XZmKL {
    margin-left: -13% !important;
  }
  .styles_link__sqE3\+ {
    text-decoration: none;
    color: var(--primary);
    white-space: nowrap;
  }

  .styles_ButtonsContainer__gLbn0 {
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    height: auto;
    width: auto;
  }
  .hearderIsh {
    margin-top: -30%;
    font-size: 16px !important;
    margin-bottom: 0% !important;
  }
  .styles_ButtonsContainer__ZclKe {
    font-size: 16px !important;
  }
  .styles_row__OLInz.styles_cards__4OtJQ.minicard {
    flex-direction: column;
  }
  .styles_WhyContainer__ijPjo {
    background-color: #fff;
    height: 970px !important;
  }
  .styles_banner__HEqNl {
    width: 450px;
    display: none !important;
  }
  .styles_rightSideImage__IxSuK {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: -11% !important;
    margin-left: 5%;
  }
  .styles_ButtonsContainer__gLbn0 {
    background-color: #fff;
    padding: 10px 10px !important;
    border-radius: 5px;
    cursor: pointer;
    height: auto;
    width: auto;
  }
  .styles_NavContainer__GOOSh {
    background-color: var(--primary);
    height: 85vh !important;
  }
  .styles_cardHolder__jD6\+p {
    width: 80% !important;
    flex-direction: column !important;
    height: 85vh !important;
  }
  .styles_subcontainer__vejOM {
    width: 90% !important;
  }

  .styles_subContainer__ZRsn5 {
    width: 80% !important;
    flex-direction: column !important;
  }
  .styles_NavContainer__QSTjE {
    background-color: #000000 !important;
    height: 80vh !important;
    flex-direction: column !important;
  }
  .styles_input__YPlCF {
    width: 127% !important;
    border: 1px solid #fff;
    border-radius: 7px;
    margin-top: 38px !important;
    margin-left: -20% !important;
  }
  .textIsh {
    margin-top: 9%;
  }

  .styles_NavContainer__2zZ3- {
    background-color: #fff !important;
    height: 30vh !important;
    display: none !important;
  }
  .styles_mainContainer__Dbp0N {
    background-color: #ffffff;
    width: 100% !important;
    padding: 0px 18px !important;
    border-radius: 10px;
  }

  .styles_NavContainer__KqNkX {
    background-color: #fff;
    width: 90% !important;
    padding: 50px 20px !important;
    flex-direction: column !important;
  }
  .carousel .slide img {
    width: 100% !important;
    vertical-align: top !important;
    border: 0 !important;
    margin-top: 35% !important;
    margin-left: 13% !important;
  }
  .styles_col__kevlB.styles_text__XZmKL {
    margin-left: 20% !important;
  }

  .carousel.carousel-slider {
    position: relative;
    margin: 0;

    overflow: hidden;
    height: 66vh;
  }
  .styles_HeaderText__xSmmq {
    margin-top: 0px !important;
  }
} */
