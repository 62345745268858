.WhyContainer {
  background-color: #f2f2f4;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.subcontainer {
  width: 80%;
  justify-content: space-between;
}
.banner {
  position: relative;
}
.pos {
  position: absolute;
}

.benefitOfWayaposList {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

@media screen and (max-width: 768px) {
  .WhyContainer {
    height: auto;
    padding-block: 50px;
    align-items: center;
  }
  .benefit-of-wayapos-list {
  }
  img {
    width: 100%;
  }
}
