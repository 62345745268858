.container {
  height: 100vh;
  background-image: url("../../assets/images/BG.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.formContainer{
  width: 400px;
  background-color: #fff;
  padding: 20px 40px;
  padding-bottom: 60px;
  border-top: 5px solid #FF6700;
}
.inputsHolder{
  width: 100%;
  margin-top: 20px;
}
.width{
width: 100%;
}
.checkbox{
  margin-top: 10px;
}